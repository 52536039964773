import Avatar1 from '../images/Avatar1.png'
import Avatar2 from '../images/Avatar2.png'
import Avatar3 from '../images/Avatar3.png'
import Avatar4 from '../images/Avatar4.png'

const AvatarArr={
    "Avatar1":Avatar1,
    "Avatar2":Avatar2,
    "Avatar3":Avatar3,
    "Avatar4":Avatar4

   
}

export {AvatarArr}